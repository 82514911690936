import { ApiError, ApiResponse } from "services/ApiService";
import { ResponseData } from "services/models/TypedResponse";
import { AbstractService } from "./AbstractService";
import Document from "../models/Document";
import { FileHelper } from "services/FileHelper";
import ErrorResponse from "services/models/ErrorResponse";
import BrandItem from "services/models/BrandItem";
import SalesDocumentAddress from "services/models/SalesDocumentAddress";
import { format } from "date-fns";

export namespace SalesQuote {
  export interface EvaluationResultsItem {
    message: string;
    callingFunction: string;
    operationName: string;
  }
  export interface SkippedQuote {
    quoteNumber: string;
    evaluationResults: EvaluationResultsItem[];
  }
  export interface SalesQuote {
    brand: BrandItem;
    project: string;
    orderValue: string;
    quoteNumber: string;
    status: string;
    type: {
      name: string;
      code: string;
    };
    validFrom: string;
    validTo: string;
    currency: string;
  }
  export enum SearchMode {
    ALL = "all",
    ACTIVE = "active",
    EXPIRED = "expired",
    FUTURE = "future",
    EXPIRING_IN = "expiringIn",
  }
  export interface SalesQuoteType {
    code: string;
    name: string;
    order: number;
  }

  export interface QuoteTermsOfSale {
    paymentTerms: Array<string>;
    shippingTerms: Array<string>;
  }

  export interface QuoteDetailsItem {
    itemNumber: string;
    productNumber: string;
    description: string;
    quantity: string;
    unitPrice: string;
    lineTotal: string;
    currency: string;
    quoteGroup?: string;
    unitCount: string;
    salesUnit: string;
    taxAmount: string;
    category: string;
    discountPercentage?: string;
    eanupc: string;
  }
  export interface MaterialQuote extends SalesQuote {
    unitPrice: string;
  }
  export namespace Input {
    export interface Search {
      createdFromDate?: Date; //yyyyMMdd
      createdToDate?: Date; //yyyyMMdd
      mode?: SearchMode;
      expiringInDays?: number; // number between 1-120 days
      quoteNumber?: string;
      projectName?: string;
      quoteType?: string;
      productNumber?: string;
      accountNumber: string;
    }
    export interface GetQuotesForMaterial {
      accountNumber: string;
      startDate: string; // YYYYMMDD
      endDate: string; // YYYYMMDD
      projectName: string;
      quoteType: string;
      materialNumber: string;
      division: string;
      salesOrg: string;
    }
    export enum DocumentTypes {
      Detail = "quotedetail",
    }
  }
  export namespace Output {
    export interface Search {
      quoteResults: SalesQuote[];
      skippedQuotes: SkippedQuote[];
    }
    export interface TermsOfSale {
      paymentTerms: Array<string>;
      shippingTerms: Array<string>;
      quoteNumber: string;
    }
    export interface AggregateTermsAndConditions {
      paymentTerms: Array<string>;
      shippingTerms: Array<string>;
      generalTerms: Array<string>;
    }
    export interface Details {
      brand: BrandItem;
      items: QuoteDetailsItem[];
      status: string;
      quoteNumber: string;
      accountNumber: string;
      createdDate: string;
      validFromDate: string;
      validToDate: string;
      total: string;
      currency: string;
      headerTextNotes: string[];
      type: {
        name: string;
        code: string;
      };
      project: string;
      quoteTo: {
        name: string;
        address: SalesDocumentAddress;
      };
      billTo: {
        name: string;
        address: SalesDocumentAddress;
      };
      shipTo: {
        name: string;
        address: SalesDocumentAddress;
      };
      salesGroup: {
        name: string;
        number: string;
      };
      salesPartner: {
        name: string;
        number: string;
      };
      skippedQuote: SkippedQuote;
    }
    export interface QuotesForMaterial {
      quoteResults: MaterialQuote[];
    }
  }
  export class Service extends AbstractService {
    /**
     *
     * @param {Input.Search} query
     * @param {AbortSignal|undefined} abortSignal
     * @returns
     */
    async search(
      query: Input.Search,
      abortSignal?: AbortSignal
    ): Promise<Output.Search> {
      const headers = await this.getAuthorizationHeaders();
      // convert dates to API required string format
      let q: any = Object.assign({}, query);
      if (query.createdFromDate && query.createdToDate) {
        q.createdFromDate = format(query.createdFromDate, "yyyyMMdd");
        q.createdToDate = format(query.createdToDate, "yyyyMMdd");
      }

      const url = "quotes/Search";
      const result = await this.connection
        .get(url, {
          baseURL: this.baseUrl,
          headers: headers,
          params: q,
          signal: abortSignal,
        })
        .catch((e) => {
          if (e.response.status === 401 && query.accountNumber) {
            throw new Error(
              `User is not authorized to access account ${query.accountNumber}`
            );
          } else if (e.response?.data?.detail) {
            throw new Error(e.response.data.detail);
          } else {
            throw e;
          }
        });

      return result.data;
    }

    async getTypes(abortSignal?: AbortSignal): Promise<Array<SalesQuoteType>> {
      const headers = await this.getAuthorizationHeaders();
      const url = "/quotes/quotetypes";
      let result = await this.connection.get(url, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });

      let quoteTypes = result.data as Array<SalesQuoteType>;
      return quoteTypes;
    }

    async getDetails(
      quoteNumber: string,
      customerAccountNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.Details> {
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection.get(`quotes/${quoteNumber}`, {
        baseURL: this.baseUrl,
        headers: headers,
        params: { customerAccountNumber: customerAccountNumber },
        signal: abortSignal,
      });
      return result.data;
    }

    async getAggregateTermsAndConditions(
      quoteNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.AggregateTermsAndConditions> {
      let result1 = await this.getTerms(quoteNumber, abortSignal);
      let result2 = await this.getTermsOfSale(quoteNumber, abortSignal);
      const result = {} as Output.AggregateTermsAndConditions;
      // normalize our general terms since it comes over as an html string
      let generalTerms = new Array<string>();
      if (typeof result1 === "string") {
        result1 = result1.trim();
        if (result1.length > 0) {
          generalTerms.push(result1);
        }
      }
      result.generalTerms = generalTerms;
      // add the result from the terms of sale call
      result.paymentTerms = result2.paymentTerms;
      result.shippingTerms = result2.shippingTerms;
      return result;
    }

    async getTerms(
      quoteNumber: string,
      abortSignal?: AbortSignal
    ): Promise<string> {
      const headers = await this.getAuthorizationHeaders();

      const url = `quotes/${quoteNumber}/terms`;
      let result = await this.connection.get(url, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });
      return result.data;
    }

    async getTermsOfSale(
      quoteNumber: string,
      abortSignal?: AbortSignal
    ): Promise<Output.TermsOfSale> {
      const headers = await this.getAuthorizationHeaders();
      const url = `quotes/${quoteNumber}/termsofsale`;
      let result = await this.connection.get(url, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      });
      return result.data;
    }

    async getQuotesForMaterial(
      query: Input.GetQuotesForMaterial,
      abortSignal?: AbortSignal
    ): Promise<Output.QuotesForMaterial> {
      const headers = await this.getAuthorizationHeaders();
      const url = "quotes/formaterial";
      let result = await this.connection.post(url, {
        baseURL: this.baseUrl,
        headers: headers,
        params: query,
        signal: abortSignal,
      });
      return result.data;
    }

    async getDocument(
      quoteNumber: string,
      accountNumber: string,
      abortSignal?: AbortSignal
    ): Promise<ResponseData<Document>> {
      let downloadApiRelPath = `quotes/${quoteNumber}/pdf?accountNumber=${accountNumber}`;

      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection
        .get(downloadApiRelPath, {
          baseURL: this.baseUrl,
          headers: headers,
          responseType: "blob",
          signal: abortSignal,
        })
        .then((resp: ApiResponse) => {
          return resp;
        })
        .catch((error: ApiError) => {
          if (error.response) {
            return error.response;
          } else {
            throw new Error(error.message);
          }
        });

      let fileHelper = new FileHelper<Document>();
      let downloadResponse = await fileHelper
        .parseDownloadApiResponse(quoteNumber, result)
        .then((downloadResponse) => {
          return downloadResponse;
        })
        .catch((error: Error) => {
          throw error;
        });
      return downloadResponse;
    }

    async emailDocuments(
      email: string[],
      quoteNumbers: string[],
      accountNumber: string,
      senderEmail: string,
      cc?: string[],
      abortSignal?: AbortSignal
    ): Promise<ResponseData<any>> {
      let apiData = {
        accountNumber: accountNumber,
        to: email,
        cc: cc,
        documentType: Input.DocumentTypes.Detail,
        quoteNumbers: quoteNumbers,
        senderEmail: senderEmail,
      };
      const headers = await this.getAuthorizationHeaders();
      let result = await this.connection
        .post(this.emailQueueApiRelPath, JSON.stringify(apiData), {
          baseURL: this.documentQueueBaseUrl,
          headers: headers,
          signal: abortSignal,
        })
        .then((resp: ApiResponse) => {
          return resp;
        })
        .catch((error: ApiError) => {
          throw new Error(error.message);
        });

      let emailResult = undefined;
      if (result.status === 200) {
        emailResult = {
          error: undefined,
          results: "Requested document(s) will be emailed shortly.",
        };
      } else {
        emailResult = {
          error: result.data as ErrorResponse,
          results: undefined,
        };
        if (emailResult.error && emailResult.error.detail) {
          let details = JSON.parse(emailResult.error.detail) as string[];
          if (details && details.length > 0) {
            emailResult.error.detail = details.join(". ");
          }
        }
      }
      return emailResult;
    }
  }
}
