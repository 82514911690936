import { ApiError, ApiResponse } from "services/ApiService";
import { AbstractService } from "./AbstractService";
import { ResponseData } from "../models/TypedResponse";
import Document from "../models/Document";
import { AxiosError, AxiosResponse } from "axios";
import { FileHelper } from "services/FileHelper";

export namespace PriceFiles {

    export interface PriceFile  {
      priceFileId: string;
      accountNumber: string;
      brand: string;
      customerName: string;
      address: string;
      branch: string;
      salesOrg: string;
      division: string;
  }

  export namespace Input {
    export interface PriceFileSearch {
        pageSize?: number;
        page?: number;
        searchTerm: string;
    }
  }

  export namespace Output {
    export interface PriceFileSearch<PriceFile> {
        TotalCount: number;
        NumberOfPages: number;
        PageSize: number;
        PriceFiles: PriceFile[];
    }
  }

  export class Service extends AbstractService {
    /**
     *
     * @param {number} query.pageSize
     * @param {number} query.page
     * @param {string} query.searchTerm
     * @param {AbortSignal|undefined} signal
     * @returns Promise<Output.PriceFileSearch> 
     */
    async PriceFileSearch(
      query: Input.PriceFileSearch,
      abortSignal?: AbortSignal
    ): Promise<Output.PriceFileSearch<PriceFile>> {
      const headers = await this.getAuthorizationHeaders();
      const data = {
        limit: query.pageSize,
        offset: query.page,
        searchTerm: query.searchTerm,
      };

      const url = "/pricefiles";

      let r = await this.connection.post(url, data, {
        baseURL: this.baseUrl,
        headers: headers,
        signal: abortSignal,
      }).then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          return error.response;
        } else {
          throw new Error(error.message);
        }
      });
      return r.data;
    }

    /**
     *
     * @param {string} priceFileId
     * @param {AbortSignal|undefined} signal
     * @returns Promise<ResponseData<Document>>
     */
    async getPriceFile(
      priceFileId: string,
      abortSignal?: AbortSignal
    ): Promise<ResponseData<Document>> {
      const headers = await this.getAuthorizationHeaders();
      
      const url = "/pricefiles/{1}".replace(
        "{1}",
        encodeURIComponent(priceFileId)
      );

      let result = await this.connection
        .get(url, {
          baseURL: this.baseUrl,
          headers: headers,
          responseType: "blob",
          signal: abortSignal,
        })
        .then((resp: ApiResponse) => {
          return resp;
        })
        .catch((error: ApiError) => {
          if (error.response) {
            return error.response;
          } else {
            throw new Error(error.message);
          }
        });
      let fileHelper = new FileHelper<Document>();
      let downloadResponse = await fileHelper
        .parseDownloadApiResponse(priceFileId, result)
        .then((downloadResponse) => {
          return downloadResponse;
        })
        .catch((error: Error) => {
          throw error;
        });
      return downloadResponse;
    }
  }
}
