import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Provider } from "react-redux";
import { ConfigureStore } from "./store/configureStore";
import { Store, AnyAction } from "redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { MsalWindow } from "./models/App";
import { AppState } from "./store/app";
import TagManager from "react-gtm-module";
import Maintenance from "./pages/Maintenance";
import { CookiesProvider } from "react-cookie";

// Create browser history to use in the Redux store
const initialState: AppState = (window as any).initialReduxState as AppState;
const storeConfig = new ConfigureStore(initialState);
export const store: Store<AppState, AnyAction> = storeConfig.configure();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
const msalInstance = new PublicClientApplication(msalConfig);
declare global {
  interface Window extends MsalWindow {}
}

// init google tag manager
const gtmId = process.env.REACT_APP_GOOGLE_TAGMANAGER_ID || "";
TagManager.initialize({ gtmId: gtmId });

// maintenance mode
const maintenanceMode =
  process.env.REACT_APP_MAINTENANCE === "true" ? true : false;

window.msalInstance = msalInstance;
ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <StrictMode>{maintenanceMode ? <Maintenance /> : 
        <CookiesProvider>
          <App />
        </CookiesProvider>
      }
      </StrictMode>
    </MsalProvider>
  </Provider>,
  document.getElementById("root")
);
