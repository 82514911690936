import { FC, useEffect, useMemo } from "react";
import { useCart } from "features/OrderEntry/store/useCart";
import { debounce } from "lodash";
import { selectOrderId } from "features/OrderEntry/store/selectors";
import { saveOrderItem } from "features/OrderEntry/store/dispatchers";
import { ItemUpdateAsyncStateObject } from "features/OrderEntry/store/models/CartState";
import { AsyncStatus } from "store/AsyncStateObject";

const ItemUpdateListener: FC<{}> = () => {
  const { itemUpdateQueue } = useCart();
  const orderId = selectOrderId();

  const updateItems = useMemo(() => {
    return debounce(() => {
      itemUpdateQueue &&
        itemUpdateQueue.forEach((item: ItemUpdateAsyncStateObject) => {
          if (item.status === AsyncStatus.IDLE && item.data !== undefined) {
            saveOrderItem({
              item: item.data,
              time: item.time,
              orderId: orderId,
            });
          }
        });
    }, 500);
  }, [itemUpdateQueue, orderId]);

  useEffect(() => {
    if (itemUpdateQueue && itemUpdateQueue.length > 0) {
      let cancel = updateItems.cancel;

      updateItems();
      return () => {
        if (cancel) cancel();
      };
    }
  }, [itemUpdateQueue, updateItems]);

  return null;
};

export default ItemUpdateListener;
