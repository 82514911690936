import ErrorResponse from "services/models/ErrorResponse";

export enum AsyncStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export interface AsyncStateObject<T> {
  status: AsyncStatus;
  error: string | ErrorResponse | undefined;
  data: undefined | T;
}
export const isAsyncStateObject = (x: any): x is AsyncStateObject<typeof x> =>
  typeof x === "object" && "status" in x;

export function AsyncStateObjectInitialStateFactory() {
  return {
    status: AsyncStatus.IDLE,
    error: undefined,
    data: undefined,
  };
}
