import { CountryCode } from "store/system/constants";

const betaCountries: string[] = ['US'];

export const isBetaToggleCountryEnabled = (): boolean => {
  const siteCountry: string | undefined = CountryCode;

  if (siteCountry && betaCountries.includes(siteCountry)) {
    return true;
  } else {
    return false;
  }
};
