import { store } from "../../..";
import HubbellConnectOrder, {
  Address,
  Detail,
  DetailData,
  Item,
} from "models/HubbellConnectOrder";
import OrderEntryService from "services/OrderEntryService";
import CartState, { FormStatus } from "./models/CartState";
import {
  cartSlice,
  getOrderDraftForAccount,
  updatePriceAndAvailability,
  updatePriceAndAvailabilityInput,
  validateOrderItems,
  validateOrder,
  saveOrderAddress,
  updateOrderItem,
  validateOrderAddress,
} from "./reducers";
import { DataQuality } from "services/DataQuality";

export interface UpdateOrderAddress {
  orderId: string;
  orderAddressId: string;
  address: Address;
  addressType: string;
}

export interface ValidateAddress extends UpdateOrderAddress {
  orderDetailId?: string;
}

export interface UpdateOrderEntryDetails {
  orderId: string;
  orderDetailsData: DetailData;
}

export interface UpdateOrderItemInterface {
  orderId: string | undefined;
  item: Item;
  time: string;
}

export interface AddressValidationInterface {
  line1: string;
  line2: string;
  city: string;
  region: string;
  postalcode: string;
  country: string;
}

export interface DocumentURLUpdateInterface {
  orderDetailId: string;
  documentURL: string;
}

export const saveOrderItem = (payload: UpdateOrderItemInterface) =>
  store.dispatch(updateOrderItem(payload));

export const updateOrderDraftItem = (payload: {
  item: Partial<Omit<Item, "orderItemId">>;
  orderItemId: string;
  time: string;
}) => store.dispatch(cartSlice.actions.updateDraftItem(payload));

export const fetchOrderDraftForAccount = (accountNumber: string) =>
  store.dispatch(getOrderDraftForAccount(accountNumber));

export const updateOrderAddress = (
  payload: UpdateOrderAddress
): Promise<Address> => store.dispatch(saveOrderAddress(payload)).unwrap();

export const updateCart = (payload: Partial<CartState>) => {
  store.dispatch(cartSlice.actions.updateCart(payload));
};

export const updateOrderDraft = (orderDraft: Partial<HubbellConnectOrder>) => {
  store.dispatch(cartSlice.actions.updateOrderDraft(orderDraft));
  resetShoppingCartValidation();
};

export const updateDocumentUrl = (documentData: DocumentURLUpdateInterface) => {
  store.dispatch(cartSlice.actions.updateDocumentURL(documentData));
};

export const resetDocumentUrl = (orderDetailId: string) => {
  store.dispatch(cartSlice.actions.resetDocumentURL(orderDetailId));
};

export const resetCart = () => {
  store.dispatch(cartSlice.actions.resetCart());
};

export const setItemScreenValidity = (payload: FormStatus) => {
  store.dispatch(cartSlice.actions.setCartValidityStatus({ items: payload }));
};

export const setShippingScreenValidity = (payload: FormStatus) => {
  store.dispatch(
    cartSlice.actions.setCartValidityStatus({ shippingDetails: payload })
  );
};

export const setDetailsScreenValidity = (payload: FormStatus) => {
  store.dispatch(
    cartSlice.actions.setCartValidityStatus({ orderDetails: payload })
  );
};

export const updateShippingAddress = (payload: Partial<Address>) => {
  store.dispatch(cartSlice.actions.updateShippingAddress(payload));
};

export const validateAddress = async (
  payload: ValidateAddress
): Promise<DataQuality.Output.ValidateAddress> =>
  store.dispatch(validateOrderAddress(payload)).unwrap();

export const fetchOrderDraftCarrierDetails = async (salesOrg: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (salesOrg) {
    const response = await orderEntryServiceCall.OrderDraftCarrierDetails(
      salesOrg
    );
    if (response) {
      return response;
    }
  }
};

// deletes local state item and any local associations
export const deleteOrderItemFromDraft = (orderItemId: string) =>
  store.dispatch(cartSlice.actions.deleteOrderItem(orderItemId));

export const updateOrderEntryDraftDetails = async (payload: Detail[]) => {
  store.dispatch(cartSlice.actions.updateDraftOrderDetails(payload));
};

export const setOrderDetails = async (payload: Detail[]) => {
  store.dispatch(cartSlice.actions.setOrderDetails(payload));
};

export const fetchPriceAndAvailabilityForOrderItem = (
  payload: updatePriceAndAvailabilityInput
) => store.dispatch(updatePriceAndAvailability(payload));

export const validateShoppingCart = () =>
  store.dispatch(validateOrderItems()).unwrap();

export const validateWholeOrder = () =>
  store.dispatch(validateOrder()).unwrap();

export const resetShoppingCartValidation = () =>
  store.dispatch(cartSlice.actions.resetCartValidation());

export const setOrderCurrency = (payload: Partial<string>) => {
  store.dispatch(cartSlice.actions.setOrderCurrency(payload));
};
