import {
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { SearchingIcon } from "components/SearchingIcon";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import {
  mapOrderEventPayloadToOrderEntryRequest,
  deleteDraftOrders,
  changeCurrentAccount,
} from "util/ordersHelper";
import { setSystemUserCurrentAccount } from "store/system/dispatchers";
import {
  selectSystemCurrentAccountNumber,
  selectSystemUserProfile,
} from "store/system/selectors";
import HubbellConnectOrder, {
  Item,
  OrderSourceName,
  OrderTypeName,
} from "models/HubbellConnectOrder";
import OrderEntryService from "services/OrderEntryService";
import { AsyncStateObject, AsyncStatus } from "store/AsyncStateObject";
import { Notify } from "store/system/notifications/helpers";
import ErrorPanel from "features/common/components/ErrorPanel";
import { ApiError } from "services/ApiService";
import NotificationLevel from "store/system/notifications/models/NotificationLevel";
import { dispatchRefreshCartEvent } from "./events";
import {
  brandSupportsOrderEntry,
  checkIfSampleOrder,
} from "features/OrderEntry/store/helpers";
import { brandSupportsReplacementParts } from "features/ReplacementParts/store/helpers";
import {
  OrderEventItem,
  OrderEventPayload,
} from "../OrderEntryActionButton/models/OrderEventPayload";

enum ModalView {
  AddedToCart = "Added to cart",
  AddingToCart = "Adding to cart",
  Error = "Error",
  ExistingDraftFound = "Existing draft found",
  Redirecting = "Redirecting",
  ReplacementPartsWarranty = "Replacement part warranty",
  LineItemExceeded = "Line Item Exceeded",
}

const OrderEventModal: FC<{
  onClose: Function;
  payload: OrderEventPayload;
}> = ({ onClose, payload }): JSX.Element => {
  // react-router navigate
  const navigate = useNavigate();
  // user state data
  const systemUserCurrentAccount = selectSystemCurrentAccountNumber();
  const systemUserIdentityId = selectSystemUserProfile().identityApiId;
  // current view
  const [view, setView] = useState<ModalView>(ModalView.AddingToCart);
  // fetched draft data
  const [accountDraft, setAccountDraft] = useState<
    AsyncStateObject<HubbellConnectOrder>
  >({
    status: AsyncStatus.IDLE,
    data: undefined,
    error: undefined,
  });
  // action result data
  const [addToCartResult, setAddToCartResult] = useState<
    AsyncStateObject<HubbellConnectOrder | PromiseSettledResult<Item>[]>
  >({
    status: AsyncStatus.IDLE,
    data: undefined,
    error: undefined,
  });
  // invalid items
  const [invalidItems, setInvalidItems] = useState<
    { data: OrderEventItem; reason: string }[]
  >([]);
  // failed order updates
  const [failedUpdateItems, setFailedUpdateItems] = useState<
    { data: OrderEventItem; reason: string }[]
  >([]);

  // Create a new order draft
  const createOrder = useCallback(
    (payload: OrderEventPayload) => {
      const filteredPayload = { ...payload };

      setView(ModalView.AddingToCart);

      setAddToCartResult({
        data: undefined,
        status: AsyncStatus.LOADING,
        error: undefined,
      });
      const { validItems, invalidItems } = filterInvalidPayloadItems(payload);
      if (invalidItems.length) {
        setInvalidItems(invalidItems);
      }
      if (validItems.length) {
        filteredPayload.items = validItems;
        attemptCreateOrder(filteredPayload)
          .then((response: any) => {
            setAddToCartResult({
              data: response,
              status: AsyncStatus.SUCCEEDED,
              error: undefined,
            });
            setView(ModalView.AddedToCart);
          })
          .catch((error: ApiError) => {
            setAddToCartResult({
              data: undefined,
              status: AsyncStatus.FAILED,
              error:
                error?.response?.data ||
                error?.message ||
                "An unknown error occurred while trying to create your order.",
            });
            setView(ModalView.Error);
          })
          .finally(() => {
            if (payload.accountNumber === systemUserCurrentAccount) {
              dispatchRefreshCartEvent();
            }
          });
      } else {
        console.error("No valid items provided to createOrder", payload);
        setView(ModalView.AddedToCart);
        if (payload.accountNumber === systemUserCurrentAccount) {
          dispatchRefreshCartEvent();
        }
      }
    },
    [systemUserCurrentAccount]
  );

  // Used for PnA order when a valid (standard) draft order exists
  const updateOrder = useCallback(
    async (payload: OrderEventPayload, existingDraft: HubbellConnectOrder) => {
      if (!existingDraft.orderId) {
        throw Error("orderId required.");
      }
      setView(ModalView.AddingToCart);
      setAddToCartResult({
        data: undefined,
        status: AsyncStatus.LOADING,
        error: undefined,
      });
      const { validItems, invalidItems } = filterInvalidPayloadItems(payload);
      if (invalidItems.length) {
        setInvalidItems(invalidItems);
      }
      if (validItems.length) {
        const filteredPayload = { ...payload, items: validItems };
        attemptUpdateOrder(filteredPayload, existingDraft.orderId)
          .then((response) => {
            // this response is an array of settled promises
            // need to figure out if there were any failures and store those
            const { failedItems } = countMyPromises(response);
            setFailedUpdateItems(
              failedItems.map((f, i) => ({
                data: filteredPayload.items[i],
                reason: f,
              }))
            );
            setAddToCartResult({
              data: response,
              status: AsyncStatus.SUCCEEDED,
              error: undefined,
            });
            setView(ModalView.AddedToCart);
          })
          .catch((error: ApiError) => {
            setAddToCartResult({
              data: undefined,
              status: AsyncStatus.FAILED,
              error:
                error?.response?.data ||
                error.message ||
                "An unknown error occurred while trying to update your order.",
            });
            setView(ModalView.Error);
          })
          .finally(() => {
            if (payload.accountNumber === systemUserCurrentAccount) {
              dispatchRefreshCartEvent();
            }
          });
      } else {
        console.error("No valid items provided to updateOrder", payload);
        setView(ModalView.AddedToCart);
      }
    },
    [systemUserCurrentAccount]
  );

  // Used for order,quote,replacement-* orders
  const handleReplaceDraft = useCallback(
    (payload: OrderEventPayload) => {
      setView(ModalView.AddingToCart);
      if (accountDraft.data?.orderId) {
        deleteDraftOrders(accountDraft.data.orderId)
          .then((resp) => {
            if (resp === true) {
              if (isReplacementParts(payload.orderType)) {
                setView(ModalView.ReplacementPartsWarranty);
              } else {
                createOrder(payload);
              }
            }
          })
          .catch((error: ApiError) => {
            console.error("Error deleting order draft:", error.toJSON());
            const date = new Date();
            const time = date.getTime();
            Notify({
              id: time.toString(),
              allowDismiss: true,
              message: "An error occurred while attempting to delete draft.",
              title: "A error occurred",
              level: NotificationLevel.DANGER,
            });
          });
      }
    },
    [accountDraft.data?.orderId, createOrder]
  );

  // Sets order type based on user response to warranty prompt
  const handleReplacementPartsOrderWarrantyResponse = useCallback(
    (isWarranty: boolean, payload: OrderEventPayload) => {
      const mappedPayload = { ...payload };
      if (isWarranty) {
        mappedPayload.orderType = OrderTypeName.ReplacementPartWarranty;
      }
      createOrder(mappedPayload);
    },
    [createOrder]
  );

  // Redirects to the shopping cart
  const handleViewCart = useCallback(() => {
    if (systemUserCurrentAccount !== payload.accountNumber) {
      setView(ModalView.Redirecting);
      changeCurrentAccount(
        systemUserIdentityId,
        parseInt(payload.accountNumber)
      ).then((r) => {
        if (r.data?.customers?.length > 0) {
          setSystemUserCurrentAccount(
            r.data.customers[0],
            selectSystemUserProfile()
          );
          onClose();
          navigate("/order-entry");
        }
      });
    } else {
      onClose();
      navigate("/order-entry");
    }
  }, [
    systemUserCurrentAccount,
    systemUserIdentityId,
    payload.accountNumber,
    onClose,
    navigate,
  ]);

  /* effect hook to get existing draft for account number in payload */
  useEffect(() => {
    let orderEntryService = new OrderEntryService();
    let orderAccountNumber = Number(payload.accountNumber).toString();
    // fetching the draft from remote to eliminate cases where the local and remote state are out of sync
    // this hook should only run once
    if (accountDraft.status === AsyncStatus.IDLE) {
      setAccountDraft({
        ...accountDraft,
        status: AsyncStatus.LOADING,
        error: undefined,
      });
      // fetch input account draft data
      orderEntryService
        .OrderDraftForAccountNumber(orderAccountNumber)
        // draft found
        .then((resp: any) => {
          setAccountDraft({
            data: resp,
            status: AsyncStatus.SUCCEEDED,
            error: undefined,
          });
        })
        .catch((error: ApiError) => {
          setAccountDraft({
            data: undefined,
            status: AsyncStatus.FAILED,
            error: error.response?.data || error.message,
          });
          if (error.response?.status === 404 && error.response.data) {
            console.info("No draft found for account: " + orderAccountNumber);
          } else {
            // we have an actual error
            console.error(error);
            setView(ModalView.Error);
          }
        });
    }
  }, [accountDraft, createOrder, payload]);

  /* effect hook to progress workflow once draft information is obtained */
  useEffect(() => {
    // exit if account draft isn't loaded
    // or if addToCartResult status isn't idle
    // this hook should only run once
    if (
      accountDraft.status === AsyncStatus.IDLE ||
      accountDraft.status === AsyncStatus.LOADING ||
      addToCartResult.status !== AsyncStatus.IDLE
    ) {
      return;
    }

    const hasExistingDraft =
      accountDraft.status === AsyncStatus.SUCCEEDED &&
      accountDraft.data !== undefined &&
      accountDraft.data?.orderId !== undefined;

    const payloadIsReorder =
      payload.source === OrderSourceName.Quote ||
      payload.source === OrderSourceName.Reorder;
    const payloadIsPnA =
      payload.source === OrderSourceName.New &&
      (payload.orderType === OrderTypeName.Standard ||
        payload.orderType === OrderTypeName.Sample);
    const payloadIsReplacementParts = isReplacementParts(payload.orderType);

    const draftIsReplacementParts =
      accountDraft.data &&
      isReplacementParts(accountDraft.data.orderType?.name || "");
    // we must replace any existing draft if the payload is a reorder, quote, or replacement part order
    // or if the existing draft is a replacement parts order
    const limitLineItems = 250;
    const hasLineItemExceeded = payload.items.length > limitLineItems;

    if (hasLineItemExceeded) {
      setView(ModalView.LineItemExceeded);
    } else if (
      hasExistingDraft &&
      /* source is replacement part*/
      (payloadIsReplacementParts ||
        /* source is order or quote*/
        payloadIsReorder ||
        /* existing is replacement part*/
        draftIsReplacementParts)
    ) {
      if (accountDraft.data?.items && accountDraft.data.items?.length > 0) {
        setView(ModalView.ExistingDraftFound);
      } else {
        handleReplaceDraft(payload);
      }
    } else {
      if (
        // allow P&A cart actions to update the existing cart
        hasExistingDraft &&
        payloadIsPnA &&
        accountDraft.data
      ) {
        updateOrder(payload, accountDraft.data);
      } else {
        // order is replacement part
        if (
          payload.orderType !== OrderTypeName.Standard &&
          payload.orderType !== OrderTypeName.Sample
        ) {
          setView(ModalView.ReplacementPartsWarranty);
        }
        // order is standard
        else {
          createOrder(payload);
        }
      }
    }
  }, [
    accountDraft,
    updateOrder,
    payload,
    createOrder,
    addToCartResult.status,
    handleReplaceDraft,
  ]);

  return (
    <Modal
      id="cartEventModal"
      dialogClassName="modal-lg"
      show={true}
      backdrop="static"
      centered={true}
    >
      <Modal.Header>
        <h4>
          {view === ModalView.AddingToCart && null}
          {(view === ModalView.Error ||
            view === ModalView.LineItemExceeded) && (
            <>
              <FontAwesomeIcon icon={faCircleExclamation} color="red" /> {view}
            </>
          )}
          {view === ModalView.Redirecting && null}
          {view === ModalView.ExistingDraftFound && (
            <>
              <FontAwesomeIcon icon={faCircleQuestion} color="#ffd100" /> {view}
            </>
          )}
          {view === ModalView.AddedToCart && (
            <>
              <FontAwesomeIcon
                icon={
                  payload.items.length ===
                  invalidItems.length + failedUpdateItems.length
                    ? faCircleExclamation
                    : faCircleCheck
                }
                className={
                  payload.items.length ===
                  invalidItems.length + failedUpdateItems.length
                    ? "text-warning"
                    : "text-success"
                }
              />{" "}
              {`${
                payload.items.length -
                (invalidItems.length + failedUpdateItems.length)
              } item(s) added to your cart.`}
            </>
          )}
          {view === ModalView.ReplacementPartsWarranty && (
            <>
              <FontAwesomeIcon icon={faCircleQuestion} color="#ffd100" /> {view}
            </>
          )}
        </h4>
        <div className="d-flex justify-content-end">
          <button
            className="btn close"
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      <ModalBody>
        {view === ModalView.AddingToCart && (
          <>
            <div className="text-center">
              <SearchingIcon
                title={"Please wait while we configure your cart"}
              />
            </div>
          </>
        )}
        {view === ModalView.Error && (
          <>
            {accountDraft?.error &&
              !addToCartResult.error &&
              (typeof accountDraft.error !== "string" ? (
                <ErrorPanel error={accountDraft.error} />
              ) : (
                <>accountDraft.error</>
              ))}
            {addToCartResult?.error &&
              (typeof addToCartResult.error !== "string" ? (
                <ErrorPanel error={addToCartResult.error} />
              ) : (
                <>addToCartResult.error</>
              ))}
          </>
        )}
        {view === ModalView.Redirecting && (
          <div className="text-center">
            <SearchingIcon title={"Redirecting to Shopping Cart"} />
          </div>
        )}
        {view === ModalView.ExistingDraftFound && (
          <div className="existingDraftContent">
            <b>
              A draft order already exists for account {payload.accountNumber}.
            </b>
            <br />
            <span>This action will replace the existing draft.</span>
            <br />
            <span>Do you want to continue?</span>
            <br />
          </div>
        )}
        {view === ModalView.LineItemExceeded && (
          <div className="existingDraftContent">
            <span>
              An order cannot be created from existing orders with over{" "}
              {payload.items.length} lines. Please contact customer service
              should you need this order duplicated.
            </span>
          </div>
        )}
        {view === ModalView.AddedToCart && (
          <>
            {/** invalid items message */}
            {invalidItems.length > 0 && (
              <Alert className="invalidItemsMessage" variant="warning">
                <strong>
                  Some items are not available for order using Hubbell Connect
                  at this time. The following have been removed from your order:
                </strong>
                <div className="items">
                  {invalidItems.map((i, idx) => (
                    <div key={`invalid-${idx}`}>
                      <span className="materialNumber">
                        {i.data.materialNumber}
                      </span>
                      <span className="reason">{i.reason}</span>
                    </div>
                  ))}
                </div>
              </Alert>
            )}
            {/** failed items message */}
            {failedUpdateItems.length > 0 && (
              <Alert className="failedItemsMessage" variant="danger">
                <strong>
                  We're sorry. The following items could not be added to your
                  order:
                </strong>
                <div className="items">
                  {failedUpdateItems.map((i, idx) => (
                    <div key={`failed-${idx}`}>
                      <span className="materialNumber">
                        {i.data.materialNumber}
                      </span>
                      <span className="reason">{i.reason}</span>
                    </div>
                  ))}
                </div>
              </Alert>
            )}
            {/** valid items */}
            {payload.items.length >
              invalidItems.length + failedUpdateItems.length && (
              <table className="cartItems table">
                <thead>
                  <tr>
                    <th>Product Information</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {payload.items
                    .filter((i) => {
                      return !invalidItems
                        .map((iv) => iv.data.materialNumber)
                        .includes(i.materialNumber);
                    })
                    .filter((i) => {
                      return !failedUpdateItems
                        .map((fv) => fv.data.materialNumber)
                        .includes(i.materialNumber);
                    })
                    .map((i, idx) => {
                      return (
                        <tr key={`valid-${idx}`}>
                          <td className="materialInfo">
                            <div className="materialNumber">
                              {i.materialNumber}
                            </div>
                            <div className="brandName">{i.brandName}</div>
                            <div className="description">{i.description}</div>
                          </td>
                          <td className="quantity">{i.quantity}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            {/** render valid payload items  */}
          </>
        )}
        {view === ModalView.ReplacementPartsWarranty && (
          <div className="replacementPartWarranty">
            <p>Is this replacement part order related to warranty claim?</p>
          </div>
        )}
        {/*renderModalContent(stage, payload, accountDraft, addToCartResult)*/}
      </ModalBody>
      <ModalFooter>
        {view === ModalView.Error && (
          <Button
            variant="primary"
            onClick={() => {
              onClose();
            }}
          >
            OK
          </Button>
        )}
        {view === ModalView.ExistingDraftFound && (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                onClose();
              }}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => handleReplaceDraft(payload)}
            >
              Yes
            </Button>
          </>
        )}
        {view === ModalView.AddedToCart && (
          <>
            {systemUserCurrentAccount !==
              Number(payload?.accountNumber).toString() && (
              <div className="text-center mx-auto">
                <small>
                  Clicking "view cart" will change your current active account.
                </small>
              </div>
            )}
            <div>
              <Button variant="secondary" onClick={() => handleViewCart()}>
                VIEW CART
              </Button>{" "}
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                CONTINUE
              </Button>
            </div>
          </>
        )}
        {view === ModalView.ReplacementPartsWarranty && (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                handleReplacementPartsOrderWarrantyResponse(false, payload);
              }}
            >
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleReplacementPartsOrderWarrantyResponse(true, payload);
              }}
            >
              Yes
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default OrderEventModal;

const filterInvalidPayloadItems = (payload: OrderEventPayload) => {
  let invalidItems: { data: OrderEventItem; reason: string }[] = [];
  let validItems: OrderEventItem[] = [];
  const sampleOrder = checkIfSampleOrder(payload.accountNumber);
  payload.items.forEach((item: OrderEventItem, i: number) => {
    let isValidItem: boolean = false;
    let reason: string = "";
    if (sampleOrder) {
      isValidItem = brandSupportsOrderEntry(item.salesOrg, item.division);
      reason = "Item brand is not permitted for sale through Hubbell Connect.";
    } else {
      // if not standard, then replacement parts order
      if (payload.orderType === OrderTypeName.Standard) {
        isValidItem = brandSupportsOrderEntry(item.salesOrg, item.division);
        reason =
          "Item brand is not permitted for sale through Hubbell Connect.";
      } else {
        isValidItem = brandSupportsReplacementParts(
          item.salesOrg,
          item.division
        );
        reason =
          "Replacement part item brand is not permitted for sale through Hubbell Connect.";
      }
      let price = Number(item.unitPrice);
      if ((isValidItem && isNaN(price)) || price <= 0) {
        isValidItem = false;
        reason = "Price is invalid.";
      }
    }
    if (!isValidItem) {
      invalidItems.push({ data: item, reason: reason });
    } else {
      validItems.push({ ...item });
    }
  });
  return { validItems, invalidItems };
};

const attemptCreateOrder = async (payload: OrderEventPayload) => {
  const orderEntryService = new OrderEntryService();

  let orderInput = mapOrderEventPayloadToOrderEntryRequest(payload);
  let orderOutput = await orderEntryService.createOrder(orderInput);
  return orderOutput;
};

const attemptUpdateOrder = async (
  payload: OrderEventPayload,
  orderId: string
): Promise<PromiseSettledResult<Item>[]> => {
  const orderEntryService = new OrderEntryService();
  let orderInput = mapOrderEventPayloadToOrderEntryRequest(payload);
  const calls: Promise<Item>[] = [];
  // loop through each item in the payload and attempt to update the order draft
  // synchronous to prevent API issues with line number
  for (const item of orderInput.items) {
    await orderEntryService
      .updateOrderDraft(item, orderId)
      .then((r) => {
        calls.push(Promise.resolve(r));
      })
      .catch((e) => {
        calls.push(
          Promise.reject(
            e.response?.data.detail || e.message || "Unknown error"
          )
        );
      });
  }
  return await Promise.allSettled(calls);
};
function countMyPromises(response: PromiseSettledResult<Item>[]) {
  let successfulItems: Item[] = [];
  let failedItems: string[] = [];

  response.forEach((r, i) => {
    if (r.status === "rejected") {
      failedItems[i] = r.reason.response?.data?.message || r.reason.message;
    }
    if (r.status === "fulfilled") {
      successfulItems[i] = r.value;
    }
  });
  return { successfulItems, failedItems };
}

export const isReplacementParts = (orderType: string) =>
  [
    OrderTypeName.ReplacementPart.toString(),
    OrderTypeName.ReplacementPartWarranty.toString(),
  ].includes(orderType?.toLowerCase());

export const isReplacementPartsWarranty = (orderType: string) =>
  OrderTypeName.ReplacementPartWarranty.toString() === orderType?.toLowerCase();
