import { useEffect, useState, FC, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { isBetaToggleCountryEnabled } from "util/betaToggleHelper";

const BetaToggleHeader: FC = (): ReactNode => {
  const [cookies, setCookies] = useCookies(["versionCookie"]);
  const [toggle, setToggle] = useState(false);
  // URLs for dev
  const versionOneDev: any = process.env.REACT_APP_VERSION_ONE_DEV;
  const versionTwoDev: any = process.env.REACT_APP_VERSION_TWO_DEV;
  // URLs for QA
  const versionOneQa: any = process.env.REACT_APP_VERSION_ONE_QA;
  const versionTwoQa: any = process.env.REACT_APP_VERSION_TWO_QA;
  // URLs for PROD
  const versionOneProd: any = process.env.REACT_APP_VERSION_ONE_PROD;
  const versionTwoProd: any = process.env.REACT_APP_VERSION_TWO_PROD;

  const showToggle = process.env.REACT_APP_SHOW_BETA_TOGGLE;
  const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

  const isBetaToggleEnabledForCountry = isBetaToggleCountryEnabled();
  // set state based on cookie and redirect if needed
  // or initialize the cookie
  useEffect(() => {
    if (cookies.versionCookie !== "one") {
      setCookies("versionCookie", "one", { path: "/" });
      setToggle(false);
    } else {
      if (cookies.versionCookie === "one") {
        setToggle(false);
      } else {
        setToggle(true);
      }
    }
  }, [cookies.versionCookie, setCookies]);

  // handle toggle: set cookie and
  // redirect to correct version in correct environment
  const onToggleAction = (): void => {
    switch (environment) {
      case "DEV":
        // redirect between DEV v1 and v2
        if (!toggle) {
          setCookies("versionCookie", "two", { path: "/" });
          window.location.href = versionTwoDev;
        } else {
          setCookies("versionCookie", "one", { path: "/" });
          window.location.href = versionOneDev;
        }
        setToggle(toggle);
        break;
      case "QA":
        // redirect between QA v1 and v2
        if (!toggle) {
          setCookies("versionCookie", "two", { path: "/" });
          window.location.href = versionTwoQa;
        } else {
          setCookies("versionCookie", "one", { path: "/" });
          window.location.href = versionOneQa;
        }
        setToggle(toggle);
        break;
      case "PROD":
        // redirect between PROD v1 and v2
        if (!toggle) {
          setCookies("versionCookie", "two", { path: "/" });
          window.location.href = versionTwoProd;
        } else {
          setCookies("versionCookie", "one", { path: "/" });
          window.location.href = versionOneProd;
        }
        setToggle(toggle);
        break;
    }
  };
  return (
    <>
      {showToggle === "true" && isBetaToggleEnabledForCountry && (
        <div className="beta-header">
          <Form>
            <Form.Check
              reverse
              type="switch"
              id="beta-switch"
              label={
                cookies.versionCookie === "two"
                  ? "The New Hubbell Connect"
                  : "Try the New Hubbell Connect"
              }
              className="text-white beta-switch"
              defaultChecked={toggle}
              onChange={onToggleAction}
            />
          </Form>
        </div>
      )}
    </>
  );
};
export default BetaToggleHeader;
