import { isDate, isValid } from "date-fns";
import OrderEntryService from "services/OrderEntryService";
import { userAuthorizedFeature } from "store/system/helpers";
import {
  Address,
  Detail,
  Item,
  OrderShipmentType,
} from "models/HubbellConnectOrder";
import { selectCartOrderDetail, selectCartValidation } from "./selectors";
import { AsyncStatus } from "store/AsyncStateObject";
import InventoryEntry from "models/InventoryItem";
import { OrderDetailFormModel } from "./models/OrderDetailFormModel";
import { CountryCode } from "store/system/constants";
import { DataQuality } from "services/DataQuality";
import { validateOrderItem } from "./models/OrderValidation";

const hubbellConnect_Country = CountryCode;

const OrderEntry_Group = [
  { salesOrg: "C300", division: "3A" },
  { salesOrg: "C300", division: "3B" },
  { salesOrg: "C300", division: "3C" },
  { salesOrg: "C351", division: "84" },
  { salesOrg: "C351", division: "3A" },
  { salesOrg: "C351", division: "3B" },
  { salesOrg: "C351", division: "3C" },
  { salesOrg: "C351", division: "3K" },
  { salesOrg: "C351", division: "3L" },
  { salesOrg: "C351", division: "3M" },
  { salesOrg: "C351", division: "3P" },
  { salesOrg: "C351", division: "4A" },
  { salesOrg: "C351", division: "4B" },
  { salesOrg: "C351", division: "4C" },
  { salesOrg: "C351", division: "4D" },
  { salesOrg: "C351", division: "4H" },
  { salesOrg: "C351", division: "4J" },
  { salesOrg: "C351", division: "6B" },
  { salesOrg: "C351", division: "7A" },
  { salesOrg: "C351", division: "7E" },
  { salesOrg: "C362", division: "3K" },
  { salesOrg: "C362", division: "3A" },
  { salesOrg: "C362", division: "3B" },
  { salesOrg: "C362", division: "3C" },
  { salesOrg: "C362", division: "3L" },
  { salesOrg: "C362", division: "3M" },
  { salesOrg: "C362", division: "3P" },
  { salesOrg: "C362", division: "4A" },
  { salesOrg: "C362", division: "4B" },
  { salesOrg: "C362", division: "4C" },
  { salesOrg: "C362", division: "4D" },
  { salesOrg: "C362", division: "4H" },
  { salesOrg: "C362", division: "4J" },
  { salesOrg: "C362", division: "5A" },
  { salesOrg: "C362", division: "5B" },
  { salesOrg: "C362", division: "5C" },
  { salesOrg: "C362", division: "5E" },
  { salesOrg: "C362", division: "5H" },
  { salesOrg: "C362", division: "5I" },
  { salesOrg: "C362", division: "5K" },
  { salesOrg: "C362", division: "5M" },
  { salesOrg: "C362", division: "5P" },
  { salesOrg: "C362", division: "5Q" },
  { salesOrg: "C362", division: "5R" },
  { salesOrg: "C362", division: "5V" },
  { salesOrg: "C362", division: "5W" },
  { salesOrg: "C362", division: "6A" },
  { salesOrg: "C362", division: "6B" },
  { salesOrg: "C362", division: "6C" },
  { salesOrg: "C362", division: "6D" },
  { salesOrg: "C362", division: "6G" },
  { salesOrg: "C362", division: "6H" },
  { salesOrg: "C362", division: "6J" },
  { salesOrg: "C362", division: "6K" },
  { salesOrg: "C362", division: "6M" },
  { salesOrg: "C362", division: "6N" },
  { salesOrg: "C362", division: "6P" },
  { salesOrg: "C362", division: "6R" },
  { salesOrg: "C362", division: "6T" },
  { salesOrg: "C362", division: "6V" },
  { salesOrg: "C362", division: "7A" },
  { salesOrg: "C362", division: "7B" },
  { salesOrg: "C362", division: "7C" },
  { salesOrg: "C362", division: "7D" },
  { salesOrg: "C362", division: "7E" },
  { salesOrg: "C400", division: "4A" },
  { salesOrg: "C400", division: "4B" },
  { salesOrg: "C400", division: "4J" },
  { salesOrg: "C400", division: "7A" },
  { salesOrg: "C610", division: "60" },
  { salesOrg: "C630", division: "60" },
  { salesOrg: "C640", division: "60" },
  { salesOrg: "C610", division: "6A" },
  { salesOrg: "C610", division: "6B" },
  { salesOrg: "C610", division: "6C" },
  { salesOrg: "C610", division: "6E" },
  { salesOrg: "C610", division: "6J" },
  { salesOrg: "C610", division: "6M" },
  { salesOrg: "C610", division: "6N" },
  { salesOrg: "C610", division: "6R" },
  { salesOrg: "C610", division: "6S" },
  { salesOrg: "C610", division: "6T" },
  { salesOrg: "C610", division: "6U" },
  { salesOrg: "C610", division: "6U" },
  { salesOrg: "C610", division: "6V" },
  { salesOrg: "C630", division: "6D" },
  { salesOrg: "C640", division: "6H" },
  { salesOrg: "C640", division: "6K" },
  { salesOrg: "C640", division: "6P" },
  { salesOrg: "C300", division: "3E" },
];

const OrderEntry_Exports_Group = [{ salesOrg: "C300", division: "3E" }];

const ExtendedOrderNotes_Group = [
  { salesOrg: "C610", division: "60" },
  { salesOrg: "C630", division: "60" },
  { salesOrg: "C640", division: "60" },
];

const PayWithCreditCard_Group = [{ salesOrg: "C300", division: "3E" }];

const ExcludedQuoteTypeCodes = ["ZSND", "ZSN1"];

export const isExcludedQuotType = (quoteTypeCode: string) =>
  ExcludedQuoteTypeCodes.includes(quoteTypeCode.trim().toUpperCase());

export function enableCart() {
  return userAuthorizedFeature("order-entry");
}

export function brandSupportsPayByCreditCard(
  salesOrg: string,
  headerDivision: string
) {
  return (
    PayWithCreditCard_Group.find(
      (i) => i.division === headerDivision && i.salesOrg === salesOrg
    ) !== undefined
  );
}

export function brandSupportsExports(salesOrg: string, headerDivision: string) {
  return (
    OrderEntry_Exports_Group.find(
      (i) => i.division === headerDivision && i.salesOrg === salesOrg
    ) !== undefined
  );
}

export function brandSupportsOrderEntry(
  salesOrg: string,
  headerDivision: string
) {
  return (
    OrderEntry_Group.find(
      (i) => i.salesOrg === salesOrg && i.division === headerDivision
    ) !== undefined
  );
}

export const displayExtendedOrderNotes = (
  salesOrg: string,
  headerDivision: string
) => {
  let value =
    ExtendedOrderNotes_Group.find(
      (g) => g.salesOrg === salesOrg && g.division === headerDivision
    ) !== undefined;
  return value;
};

export const compareAddresses = (address1: any, address2: any) => {
  let changes: any = {};
  for (var i in address1) {
    if (
      address1[i]?.toString().toLowerCase() !==
      address2[i]?.toString().toLowerCase()
    ) {
      if (address2[i] !== undefined) {
        changes[i] = address1[i];
      }
    }
  }
  return changes;
};

export const mapInputField = (field: string) => {
  switch (field) {
    case "line1":
      return "address1";
    case "line2":
      return "address2";
    case "city":
      return "city";
    case "region":
      return "region";
    case "postalcode":
      return "postal";
    default:
      return "address1";
  }
};

export const fetchFreightTerm = async (
  accountNumber: string,
  salesOrg: string,
  division: string
) => {
  const orderEntryServiceCall = new OrderEntryService();
  const response = await orderEntryServiceCall.freightTerm(
    accountNumber,
    salesOrg,
    division
  );
  return response;
};

export const fetchFreightIncentive = async (
  accountNumber: string,
  salesOrg: string,
  division: string
) => {
  const orderEntryServiceCall = new OrderEntryService();
  const response = await orderEntryServiceCall.freightIncentive(
    accountNumber,
    salesOrg,
    division
  );
  return response;
};

export const fetchOrderDraftCarrierDetails = async (salesOrg: string) => {
  const orderEntryServiceCall = new OrderEntryService();
  if (salesOrg) {
    const response = await orderEntryServiceCall.OrderDraftCarrierDetails(
      salesOrg
    );
    return response;
  }
};

export function checkHoldForReason(
  specialInstructions: string
): RegExpMatchArray | null {
  const regEx = new RegExp(/\[Hold Reason: (.*?)\]/);
  const reasonForHoldMatch =
    specialInstructions !== null ? specialInstructions.match(regEx) : null;

  return reasonForHoldMatch;
}

export const mapOrderDetailStateToForm = (
  data: Detail,
  orderDraftType: string = "",
  isExport: boolean,
  destinationCountry: string
): OrderDetailFormModel => {
  const reasonForHoldMatch = checkHoldForReason(data?.specialInstructions);
  const reasonForHoldMatchHasValue =
    reasonForHoldMatch !== null && reasonForHoldMatch?.length > 1;
  const r = {
    orderDetailId: data?.orderDetailId,
    salesOrg: data?.salesOrg,
    division: data?.division,
    purchaseOrderNumber: data?.purchaseOrderNumber,
    purchaseOrderDate:
      data?.purchaseOrderDate &&
      isDate(data.purchaseOrderDate) &&
      isValid(data.purchaseOrderDate)
        ? new Date(data?.purchaseOrderDate)
        : data.purchaseOrderDate.toString(),
    poAttachmentUrl: data?.poAttachmentUrl || "",
    rush: data?.rush || false,
    holdForReview: data?.holdForReview || false,
    reasonForHold:
      data?.reasonForHold !== undefined && data?.reasonForHold !== ""
        ? data?.reasonForHold
        : reasonForHoldMatchHasValue
        ? reasonForHoldMatch[1]
        : "",
    shipComplete: data?.shipComplete || false,
    orderType: isExport ? OrderShipmentType.Export : OrderShipmentType.Standard,
    requestedShipDate:
      data?.requestedShipDate &&
      isDate(data.requestedShipDate) &&
      isValid(data.requestedShipDate)
        ? new Date(data?.requestedShipDate)
        : data.requestedShipDate.toString(),
    freightTermsValue: data?.freightTermsValue || "",
    freightTermsName: data?.freightTermsName || "",
    freightAccountNumber: data?.freightAccountNumber || "",
    carrierPreference: data?.carrierPreference || "",
    carrierPreferenceName: data?.carrierPreferenceName || "",
    destinationCountry: destinationCountry,
    freightThirdPartyAddress_Name: data?.freightThirdPartyAddress?.name1 || "",
    freightThirdPartyAddress_Address:
      data?.freightThirdPartyAddress?.address1 || "",
    freightThirdPartyAddress_City: data?.freightThirdPartyAddress?.city || "",
    freightThirdPartyAddress_State:
      data?.freightThirdPartyAddress?.region || "",
    freightThirdPartyAddress_PostalCode:
      data?.freightThirdPartyAddress?.postal || "",
    freightThirdPartyAddress_Country:
      data?.freightThirdPartyAddress?.country || hubbellConnect_Country,
    notes: data?.notes || "",
    carrierInstructions: data?.carrierInstructions || "",
    specialInstructions:
      data?.specialInstructions !== null
        ? reasonForHoldMatchHasValue
          ? data?.specialInstructions
              .replaceAll(reasonForHoldMatch[0], "")
              .replaceAll("\n", "")
          : data?.specialInstructions
        : "",
    markings: data?.markings || "",
    internalOrderNotes: data?.internalNotes || "",
    agentNotes: data?.agentNotes || "",
    orderDraftType: orderDraftType || "", //included to check for replacement parts
    payWithCreditCard: data?.payWithCreditCard || false,
    brandSupportsExports: brandSupportsExports(data.salesOrg, data.division),
  };
  return r;
};

export const mapOrderDetailFormModelToOrderDetail = (
  detailId: string,
  formData: Partial<OrderDetailFormModel>
) => {
  const details = selectCartOrderDetail();
  const match = details && details?.find((d) => d.orderDetailId === detailId);

  if (!match) {
    throw Error("Detail data not found");
  }

  let detailData = { ...match } as any;
  let modifiedData = { ...formData } as any;

  const dateFields = ["requestedShipDate", "purchaseOrderDate"];
  const addressFields = [
    "freightThirdPartyAddress_Name",
    "freightThirdPartyAddress_Address",
    "freightThirdPartyAddress_City",
    "freightThirdPartyAddress_State",
    "freightThirdPartyAddress_PostalCode",
    "freightThirdPartyAddress_Country",
  ];
  const addressModelFields = [
    "name1",
    "address1",
    "city",
    "region",
    "postal",
    "country",
  ];

  for (const field in formData) {
    if (dateFields.includes(field)) {
      detailData[field] =
        modifiedData[field] &&
        isDate(modifiedData[field]) &&
        isValid(modifiedData[field])
          ? modifiedData[field]?.toISOString()
          : modifiedData[field].toString();
    } else if (addressFields.includes(field)) {
      const freightThirdPartyAddress = detailData.freightThirdPartyAddress
        ? { ...detailData.freightThirdPartyAddress }
        : ({} as Address);
      const key = addressFields.findIndex((i) => i === field);
      freightThirdPartyAddress[addressModelFields[key]] = modifiedData[field];
      // make sure our default country gets populated if not set by user.
      if (!freightThirdPartyAddress.country) {
        freightThirdPartyAddress.country = hubbellConnect_Country;
      }
      detailData.freightThirdPartyAddress = freightThirdPartyAddress;
    } else {
      detailData[field] = modifiedData[field];
    }
  }
  return { ...detailData } as Detail;
};

export const returnNullIfStringEmpty = (str: string) => {
  if (str === "") {
    return null;
  } else {
    return str;
  }
};

export const compareObjects = (obj1: any, obj2: any) => {
  var changes: any = {};
  for (var i in obj1) {
    if (
      obj1[i]?.toString().toLowerCase() !== obj2[i]?.toString().toLowerCase()
    ) {
      if (obj2[i] !== undefined) {
        changes[i] = obj1[i];
      }
    }
  }
  return changes;
};

export const checkRequiredItemFieldNotEmpty = (item: Item) => {
  let valid = true;
  if (
    item &&
    (!item["purchaseOrderLineNumber"] ||
      item["purchaseOrderLineNumber"] === "" ||
      item["purchaseOrderLineNumber"] === null ||
      item["lineNumber"] === "" ||
      item["lineNumber"] === null ||
      item["materialNumber"] === "" ||
      item["materialNumber"] === null ||
      item["quantity"] === "" ||
      item["quantity"] === null ||
      item["quantity"] === "0" ||
      item["requestedShipDate"] === "" ||
      item["requestedShipDate"] === null)
  ) {
    valid = false;
  }
  return valid;
};

export const checkItemsValidity = (items: Item[]) => {
  let checks: boolean[] = [];
  items.forEach((item: Item, i) => {
    if (!checkRequiredItemFieldNotEmpty(item)) {
      checks[i] = false;
    } else {
      checks[i] = true;
    }
    if (checks[i] === true) {
      checks[i] = !draftItemHasMessages(item);
    }
  });
  if (checks.find((c) => c === false) === undefined) {
    // check for duplicate PO line number values
    let seen = new Set();
    var hasDuplicates = items.some(function (item) {
      return seen.size === seen.add(item.purchaseOrderLineNumber).size;
    });
    if (hasDuplicates) {
      checks.push(false);
    }
  }
  const isValid = checks.filter((i) => i === false).length === 0;
  return isValid;
};

export const draftItemHasMessages = (item: Item) => {
  const validationState = selectCartValidation();
  const hasValidationMessages =
    validationState.status === AsyncStatus.SUCCEEDED &&
    validationState.data &&
    validationState.data.length &&
    validationState.data.find((v) =>
      v?.items?.some((i) => i.orderItemId === item.orderItemId)
    ) !== undefined
      ? true
      : false;
  const hasPriceMessages =
    (item.skippedMaterials && item.skippedMaterials?.length > 0) ||
    (item.validationResults && item.validationResults?.length > 0) ||
    (item.serviceErrorsReturned && item.serviceErrorsReturned.length > 0);
  return hasPriceMessages || hasValidationMessages;
};

export function disableQuotesNumber(item: Item) {
  let result1 = validateOrderItem(item, "purchaseOrderLineNumber") !== true;
  let result2 = validateOrderItem(item, "quantity") !== true;
  let result3 = validateOrderItem(item, "plantNumber") !== true;
  let result4 = validateOrderItem(item, "plantDescription") !== true;
  return result1 || result2 || (result3 && result4);
}

export const checkHPSBrand = (salesOrg: string) => {
  return salesOrg.startsWith("C6");
};

export const checkDuplicatePOLine = (items: Item[]) => {
  let seen = new Set();
  var hasDuplicates = items.some(function (item) {
    return seen.size === seen.add(item.purchaseOrderLineNumber).size;
  });
  return hasDuplicates;
};

export const getUserSelectedPlant = (item: Item) => {
  let match: InventoryEntry | undefined = undefined;
  if (item?.inventory) {
    Object.values(item.inventory)?.forEach((it) => {
      it?.forEach((i) => {
        if (i.plant === item.plantNumber) {
          match = i;
        }
      });
    });
  }
  return match;
};

export const getSelectedPlant = (item: Item) => {
  let selected: InventoryEntry | undefined = getUserSelectedPlant(item);
  if (!selected) {
    if (item?.inventory) {
      const match: InventoryEntry[] | undefined = Object?.values(
        item.inventory
      )?.find((it) => {
        return it?.length > 0 ? true : false;
      });
      selected = match?.length && match.length > 0 ? match[0] : undefined;
    }
  }
  return selected;
};

export const calculateOrderItemExtendedPrice = (
  price: number,
  priceUnit: number,
  quantity: number
) => {
  return ((price / priceUnit) * quantity).toFixed(2);
};

export const timeDifference = (time1: string, time2?: string) => {
  let currentTime: Date | number = new Date().getTime() / 1000;

  if (time1) {
    let refreshTime: Date | number = new Date(time1).getTime() / 1000;
    const timeDifference = Math.floor(
      Math.abs(currentTime - refreshTime) / 3600
    );
    return timeDifference > 12 ? true : false;
  }
  if (time2) {
    let refreshTime: Date | number = new Date(time2).getTime() / 1000;
    const timeDifference = Math.floor(Math.abs(currentTime - refreshTime) / 60);
    return timeDifference > 60 ? true : false;
  }
};

export const updateItem = (item: Item) => {
  if (!item.inventory || !+item.unitPrice) {
    return true;
  } else if (item.priceLastRefreshed || item.inventoryLastRefreshed) {
    return timeDifference(item.priceLastRefreshed, item.inventoryLastRefreshed);
  }
  return false;
};

export const mapDataQualityOutputToAddress = (
  args: DataQuality.Output.ValidateAddress
): Partial<Address> => {
  return {
    address1: args.line1,
    address2: args.line2,
    city: args.city,
    region: args.region,
    postal: args.postalcode,
    country: args.country,
  };
};
export const mapAddressToDataQualityOutput = (
  args: Address
): DataQuality.Output.ValidateAddress => {
  return {
    line1: args.address1,
    line2: args.address2,
    city: args.city,
    region: args.region,
    postalcode: args.postal,
    country: args.country,
  };
};

export const hasMultipleCurrency = (items: Item[] | undefined): boolean => {
  const currencies = [...new Set(items?.map((item) => item.currency))];
  return currencies !== undefined &&
    currencies.filter(
      (item) => item !== "" && item !== undefined && item !== null
    ).length > 1
    ? true
    : false;
};

export const checkIfSampleOrder = (accountNumber: string): boolean => {
  return accountNumber.length > 0 && accountNumber[0] === "6";
};
